import styled from "styled-components";

export const Section = styled.section`
  position: relative;
  max-width: 136rem;
  width: 100%;
  margin: 19rem auto;
  padding: 6rem 4rem;
  background: rgba(0, 0, 0, 0.5);

  @media (max-width: 600px) {
    margin: 10rem auto;
    padding: 3rem 1rem;
  }
`;
